(function($) {
    setTimeout(function() {
        if ($(".owl-carousel-about").length) {
            $(".owl-carousel-about").owlCarousel({
                items: 1,
                dots: true,
                video: true,
            });
        }
        if ($(".owl-news").length) {
            $(".owl-news").owlCarousel({
                items: 1,
                dots: true,
                video: true,
            });
        }
    }, 10);
    var selector = $(".menu-item-has-children:not(.wpml-ls-current-language)");
    $(
        '<span class="text-2xl drop-down absolute right-0 px-4 cursor-pointer transform lg:hidden">></span>'
    ).appendTo(
        $(
            ".menu-item-has-children:not(.wpml-ls-current-language) .sub-menu"
        ).parents("li")
    );

    $(".drop-down").on("click", function() {
        $(this)
            .parent()
            .toggleClass("parent-active");
    });
    $(".menu-item-type-custom_taxonomy").on("click", function(e) {
        e.preventDefault();
        $(this)
            .parent()
            .parent()
            .toggleClass("parent-active");
    });
    $(".item-mobile").on("click", function(e) {
        e.preventDefault();
        $(this)
            .parent()
            .parent()
            .toggleClass("parent-active");
    });
})(jQuery);